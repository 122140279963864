<i18n>
ru:
  receiptMethod: 'Способ получения чека:'
  email: Электронный чек на e-mail
  sms: Электронный чек в SMS
  printed: Печатный чек с заказом
ua:
  receiptMethod: 'Спосіб отримання чека:'
  email: Електронний чек на e-mail
  sms: Електронний чек у SMS
  printed: Друкований чек із замовленням
us:
  receiptMethod: 'Method of receipt:'
  email: Electronic receipt by e-mail
  sms: Electronic receipt via SMS
  printed: Printed receipt with the order
</i18n>

<template>
  <div
    v-if="loaded"
    class="v-receipt__wrapper"
  >
    <div
      v-if="appConfig.VueSettingsPreRun.ReceiptSendTypes.length > 0"
      class="v-receipt__title"
      v-html="translate('receiptSend.receiptMethod')"
    />
    <arora-radio-input
      hide-label
      v-form-validator="{
        Form: validatorForm,
        Value: receiptSendType ?? null,
        Required: true,
        Validations: []
      }"
      :label="translate('receiptSend.receiptMethod')"
      :options="receiptSendTypesSorted.map((item) => item.Type)"
      v-model:selected="receiptSendType"
    >
      <template #option="option: { value: number }">
        <div v-html="receiptText(option.value)" />
      </template>
      <template #additional="option: { value: number }">
        <div
          v-if="
            option.value === ReceiptSendType.Sms &&
            receiptSendType === ReceiptSendType.Sms
          "
          class="v-mt-sm"
        >
          <common-phone-input
            required
            :label="translate('personalInfo.phoneNumber')"
            :validator-form="validatorForm"
            v-model:phone="phone"
          />
        </div>
        <div
          v-else-if="
            option.value === ReceiptSendType.Email &&
            receiptSendType === ReceiptSendType.Email
          "
          class="v-mt-sm"
        >
          <v-email-input
            class-name="v-arora-input"
            required
            v-form-validator="{
              Form: validatorForm,
              Value: email,
              Required: true,
              Validations: ['email', 'length'],
              MaxLength: 60
            }"
            :label="translate('personalInfo.email')"
            v-model:text="email"
          />
        </div>
      </template>
    </arora-radio-input>
  </div>
  <common-skeleton v-else />
</template>

<script setup lang="ts">
import type { StateOrderDataPayload } from '~types/clientStore'

import { useCommon, useValidationStore, VEmailInput, vFormValidator } from '@arora/common'

import { getActivePinia } from 'pinia'
import { ReceiptSendType } from '~api/consts'

const { clean, translate } = useI18nSanitized()
const { debounce, stringIsNullOrWhitespace } = useCommon()
const appConfig = useAppConfig()

const accountStore = useAccountStore()
const clientStore = useClientStore()
const validationStore = useValidationStore(getActivePinia())
const validatorForm = 'cart'

const loaded = ref<boolean>(true)

function receiptText(receiptSendType: number): string {
  switch (receiptSendType) {
    case ReceiptSendType.Email: {
      return translate('receiptSend.email')
    }
    case ReceiptSendType.Sms: {
      return translate('receiptSend.sms')
    }
    case ReceiptSendType.Print: {
      return translate('receiptSend.printed')
    }
  }

  return ''
}

function setEmailPhone(value: ReceiptSendType): string {
  let recipient = ''
  switch (value) {
    case ReceiptSendType.Email: {
      recipient = clientStore.ClientState?.data?.StateOrderData?.Email ?? ''
      if (
        accountStore.isLoggedIn &&
        (stringIsNullOrWhitespace(recipient) ||
          !validationStore.validateSimple(recipient, 'email'))
      ) {
        recipient = accountStore.Profile.data?.Email ?? ''
      }

      if (recipient.length > 0 && validationStore.validateSimple(recipient, 'email'))
        emailLocal.value = recipient

      break
    }
    case ReceiptSendType.Sms: {
      recipient = clientStore.ClientState?.data?.StateOrderData?.Phone ?? ''
      if (
        accountStore.isLoggedIn &&
        (stringIsNullOrWhitespace(recipient) ||
          !validationStore.validateSimple(recipient, 'phone'))
      ) {
        recipient = accountStore.Profile.data?.Phone ?? ''
      }

      if (recipient.length > 0 && validationStore.validateSimple(recipient, 'phone'))
        phoneLocal.value = recipient

      break
    }
  }
  return recipient
}

const receiptSendType = computed<ReceiptSendType>({
  get: () => clientStore.ClientState?.data?.StateOrderData?.ReceiptSendType ?? 0,
  set: (value) => {
    clientStore.updateOrderData({
      receiptRecipient: setEmailPhone(value),
      receiptSendType: value ?? 0
    })
  }
})

const phoneLocal = ref<string | null>(
  clientStore.ClientState?.data?.StateOrderData?.ReceiptRecipient ??
    clientStore.ClientState?.data?.StateOrderData?.Phone ??
    accountStore.Profile.data?.Phone ??
    null
)

const phone = computed<string>({
  get: () => {
    return (
      phoneLocal.value ??
      clientStore.ClientState?.data?.StateOrderData?.ReceiptRecipient ??
      ''
    )
  },
  set: (value) => {
    phoneLocal.value = clean(value)
    debounce(() =>
      clientStore.updateOrderData({
        receiptRecipient: phoneLocal.value ?? ''
      })
    )
  }
})

const emailLocal = ref<string | null>(
  clientStore.ClientState?.data?.StateOrderData?.ReceiptRecipient ??
    clientStore.ClientState?.data?.StateOrderData?.Email ??
    accountStore.Profile.data?.Email ??
    null
)

const email = computed<string>({
  get: () => {
    return (
      emailLocal.value ??
      clientStore.ClientState?.data?.StateOrderData?.ReceiptRecipient ??
      ''
    )
  },
  set: (value) => {
    emailLocal.value = clean(value)
    debounce(() =>
      clientStore.updateOrderData({
        receiptRecipient: emailLocal.value ?? ''
      })
    )
  }
})

const stateLoaded = computed(
  () => clientStore.ClientState?.data !== null && accountStore.Profile.state !== 'loading'
)

const receiptSendTypesSorted = appConfig.VueSettingsPreRun.ReceiptSendTypes.sort(
  (a, b) => a.SortWeight - b.SortWeight
)

function refreshFields(): void {
  if (receiptSendTypesSorted.length === 0) return

  const payload: StateOrderDataPayload = {}
  let needToUpdate = false

  if (receiptSendType.value === ReceiptSendType.Default) {
    payload.receiptSendType = receiptSendTypesSorted[0].Type
    needToUpdate = true
  } else {
    payload.receiptSendType = receiptSendType.value
  }

  if (
    stringIsNullOrWhitespace(
      clientStore.ClientState?.data?.StateOrderData?.ReceiptRecipient
    )
  ) {
    const receiptRecipient = setEmailPhone(payload.receiptSendType)

    if (!stringIsNullOrWhitespace(receiptRecipient)) {
      payload.receiptRecipient = receiptRecipient
      needToUpdate = true
    }
  }

  if (needToUpdate) {
    loaded.value = false
    clientStore.updateOrderData(payload).then(() => (loaded.value = true))
  }
}

onMounted(async () => {
  if (import.meta.client) {
    if (stateLoaded.value) {
      refreshFields()
    }
  }
})

watch(
  () => stateLoaded.value,
  (newValue) => {
    if (newValue) {
      refreshFields()
    }
  }
)

watch(
  () => clientStore.ClientState.data?.Version ?? null,
  (newContent: number | null, oldContent: number | null) => {
    if (oldContent === null) return // after initialization oldContent is null
    if (newContent === null) return // in case of client state update error

    if (oldContent !== newContent) {
      refreshFields()
    }
  }
)
</script>

<style lang="scss">
.v-receipt {
  &__wrapper {
    margin-bottom: 2rem;
    .v-radio-single {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &__title {
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
    font-weight: 600;
  }
}
</style>
