<i18n>
ru:
  title: Звонок для подтверждения заказа
  yes: Перезвонить
  no: Не перезванивать
ua:
  title: Дзвінок для підтвердження замовлення
  yes: Передзвонити
  no: Не передзвонювати
us:
  title: Should we call you to confirm your order?
  yes: Yes, you should
  no: No, you shouldn't
</i18n>

<template>
  <div
    v-if="appConfig.RestaurantSettingsPreRun.AllowDisableCallback"
    class="v-callback-required v-mb-sm -callback"
    data-test-id="callback-required"
  >
    <div class="v-title-callback-wrapper">
      <span
        class="v-title-callback"
        v-html="translate('callback.title')"
      />
      <span class="v-required" />
    </div>
    <div class="v-radio-toggler__wrapper">
      <div
        class="v-radio-toggler do_not_call_back"
        :class="{ 'v-no-selected': userOrderedCallback === CallbackNeeded.Unset }"
      >
        <input
          id="v-callback-yes"
          :value="CallbackNeeded.Callback"
          name="doNotCallback"
          type="radio"
          v-model="userOrderedCallback"
        />
        <label
          class="v-call-yes v-label-50 not-style"
          data-test-id="callback-yes"
          for="v-callback-yes"
          v-html="translate('callback.yes')"
        />

        <input
          id="v-callback-no"
          :value="CallbackNeeded.DoNotCallback"
          name="doNotCallback"
          type="radio"
          v-model="userOrderedCallback"
        />
        <label
          class="v-call-no v-label-50 not-style"
          data-test-id="callback-no"
          for="v-callback-no"
          v-html="translate('callback.no')"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CallbackNeeded } from '~api/consts'

const { translate } = useI18nSanitized()
const clientStore = useClientStore()
const appConfig = useAppConfig()

function booleanToCallbackNeeded(
  callbackNeeded: boolean | null | undefined
): CallbackNeeded {
  switch (callbackNeeded) {
    case false:
      return CallbackNeeded.DoNotCallback
    case true:
      return CallbackNeeded.Callback
    default:
      return CallbackNeeded.Unset
  }
}

function callbackNeededToBoolean(callbackNeeded: CallbackNeeded): boolean | null {
  switch (callbackNeeded) {
    case CallbackNeeded.Callback:
      return true
    case CallbackNeeded.DoNotCallback:
      return false
    default:
      return null
  }
}

const userOrderedCallback = computed({
  get: () => booleanToCallbackNeeded(clientStore.ClientState.data?.UserOrderedCallback),
  set: (value) => {
    changeCallbackState(value)
  }
})

async function changeCallbackState(userOrderedCallback: CallbackNeeded): Promise<void> {
  await clientStore.setCallbackState(callbackNeededToBoolean(userOrderedCallback))
}

onMounted(async () => {
  await clientStore.initClientState()
})
</script>

<style lang="scss">
@use 'assets/variables';
@use 'assets/mixins';

.v-title-callback {
  padding-bottom: 1rem;
  font-size: 1.375rem;

  &-wrapper {
    margin: 1.75rem 0 1rem;
  }
}

.v-radio-toggler {
  display: flex;
  gap: 4px;
  position: relative;
  .help-block {
    display: none;
  }

  &__wrapper {
    border-radius: variables.$BorderRadius;
    border: variables.$BorderWidth solid variables.$PrimaryBackgroundColor;
    background: variables.$BodyElementsBackground;
    box-shadow: variables.$InputShadow;
    padding: 4px;
  }
  &.has-error {
    border: variables.$BorderWidth solid variables.$ErrorColor;
  }

  .v-label-50 {
    flex: 1 1 50%;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 0;
    font-size: variables.$TextSizeMain;
    color: variables.$BodyTextColor;
    font-weight: 300;
    padding: 0.5rem 0;
    cursor: pointer;

    @include mixins.trans;

    &:hover {
      border-radius: calc(variables.$BorderRadius - 4px);
      background: rgba(125, 125, 125, 0.1);
      border-right: none;
    }
  }

  &.v-no-selected {
    .v-call-yes {
      border-right: 1px solid #6c6b7733;
    }
  }
  input {
    display: none;
  }

  &:hover {
    .v-label-50 {
      border-right-width: 0;
    }
  }

  input:checked + .v-label-50 {
    color: variables.$PrimaryColor;
    border-radius: calc(variables.$BorderRadius - 4px);
    background: variables.$PrimaryBackgroundColor;
  }
}
</style>
